import React, { useState, useContext } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Grid from '@material-ui/core/Grid';
import GlobalLayout from "../layouts/globalLayout";
import * as styles from './product.module.css';
import { WishlistContext } from '../provider/WishlistProvider'
import { getPrice } from "../utils/shopifyUtils";
import clsx from "clsx";
import { IconButton } from "@material-ui/core";
import { GrPrevious, GrNext } from "react-icons/gr";


const ProductTemplate = ({ pageContext }) => {
  const { product } = pageContext;
  const { handle } = product;
  const {
    addItemToWishlist,
    setIsOpen,
    wishlist
  } = useContext(WishlistContext);

  const [currentPic, setCurrentPic] = useState(0);

  const itemInWishlist = wishlist.lineItems.find(item => item.handle === handle);
  
  return (
    <GlobalLayout>
      <Grid container spacing={5} className={styles.container}>
        <Grid item sm={12} md={6} className={styles.pictures}>
          <div className={styles.imageContainer}>
            <div className={styles.mainImageContainer}>
              <GatsbyImage
                image={product.images[currentPic].gatsbyImageData}
                style={{
                  width: "100%",
                  height: "400px",
                  margin: "auto",
                  position: 'relative',
                }}
                className={styles.image}
                imgStyle={{ objectFit: "contain" }} />
              <IconButton
                onClick={() => { setCurrentPic(currentPic - 1) }}
                className={styles.previousPicture}
                disabled={currentPic === 0}>
                <GrPrevious className={styles.buttonIcon} />
              </IconButton>
              <IconButton
                onClick={() => { setCurrentPic(currentPic + 1) }}
                className={styles.nextPicture}
                disabled={currentPic === product.images.length - 1}>
                <GrNext className={styles.buttonIcon} />
              </IconButton>
            </div>
            {product.images.length > 1 && (
              <div className={styles.imageThumbNailContainer}>
                {product.images.map((img, idx) => (
                  <button
                    className={clsx(styles.imageThumbNail, { [styles.imageThumbNailActive]: currentPic === idx })}
                    onClick={() => { setCurrentPic(idx) }}>
                    <GatsbyImage
                      image={img.gatsbyImageData}
                      style={{
                        height: '100%'
                      }}
                      imgStyle={{ objectFit: "contain" }}
                    />
                  </button>
                ))}
              </div>
            )}
          </div>
        </Grid>
        <Grid item sm={12} md={6} className={styles.description}>
          <h1 className={styles.title}>{product.title}</h1>
          <div className={styles.price}><b>{`${getPrice(product.variants[0].price)} ${product.totalInventory > 1 ? "per item" : ""}`}</b></div>
          <div dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}></div>
          <button
            className={clsx(styles.addToBagButton, {
              [styles.addToBagButtonActive]: !itemInWishlist,
              [styles.addToBagButtonDisabled]: itemInWishlist
            })}
            onClick={() => {
              if (!itemInWishlist) {
                addItemToWishlist(product);
                setIsOpen(true);
              }
            }}
          >
            {itemInWishlist ? 'ALREADY IN WISHLIST' : 'ADD TO WISHLIST'}
          </button>
        </Grid>
      </Grid>
    </GlobalLayout>
  );
}
export default ProductTemplate;